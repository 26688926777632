import React from "react";
import ContactHomeStyles from "./ContactHome.styles";
import { StaticImage } from "gatsby-plugin-image";
import SocialMedia from "../../SocialMedia/SocialMedia";

const {
    Container,
    ShinySquareContainer,
    ShinySquareMiddle,
    ManInsideContainer,
    ManInsideSquare,
    TitleContainer,
    TitlePositionContainer,
    TitleBoxContainer,
    TitleBox,
    TitleText,
    SubTitleTextBottom,
    LeftSquares,
    RightSquares,
    LightCircle,
} = ContactHomeStyles;

const ContactHome = () => {
    return (
        <Container>
            <SocialMedia />

            <LeftSquares>
                <StaticImage
                    src="../../../images/Contact/left-squares.png"
                    alt="Left squares bg"
                    placeholder="blurred"
                    // quality={100}
                />
            </LeftSquares>

            <LeftSquares mobile>
                <StaticImage
                    src="../../../images/Contact/left-squares-mobile.png"
                    alt="Left squares bg"
                    placeholder="blurred"
                    width={110}
                    // quality={100}
                />
            </LeftSquares>

            <RightSquares>
                <StaticImage
                    src="../../../images/Contact/right-squares2.png"
                    alt="Left squares bg"
                    placeholder="blurred"
                    // quality={100}
                />
            </RightSquares>

            <RightSquares mobile>
                <StaticImage
                    src="../../../images/Contact/right-squares-mobile.png"
                    alt="Left squares bg"
                    placeholder="blurred"
                    width={110}
                    // quality={100}
                />
            </RightSquares>

            <LightCircle />

            <ShinySquareContainer>
                <ShinySquareMiddle>
                    <StaticImage
                        src="../../../images/Contact/rect.png"
                        alt="Shiny rectangle"
                        placeholder="blurred"
                        quality={100}
                    />
                </ShinySquareMiddle>
            </ShinySquareContainer>

            <ManInsideContainer>
                <ManInsideSquare>
                    <StaticImage
                        src="../../../images/Contact/dude.png"
                        alt="Dude inside rectangle"
                        placeholder="blurred"
                        quality={100}
                        // style={{ maxWidth: "53vw", left: "100px" }}
                    />
                </ManInsideSquare>
            </ManInsideContainer>

            <TitleContainer>
                <TitlePositionContainer>
                    <TitleBoxContainer>
                        <TitleBox>
                            <TitleText>Contact</TitleText>
                        </TitleBox>
                    </TitleBoxContainer>
                    <SubTitleTextBottom>Let's talk</SubTitleTextBottom>
                </TitlePositionContainer>
            </TitleContainer>
        </Container>
    );
};

export default ContactHome;
