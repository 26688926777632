import React from "react";
import SingleFormBoxStyles from "./SingleFormBox.styles";

const { SingleFormOutline, SingleFormRect } = SingleFormBoxStyles;

const SingleFormBox = ({ children, big }) => {
    return (
        <SingleFormOutline big={big}>
            <SingleFormRect>{children}</SingleFormRect>
        </SingleFormOutline>
    );
};

export default SingleFormBox;
