import styled from "styled-components";

const SingleFormBoxStyles = {
    SingleFormOutline: styled.div`
        width: 100%;
        height: ${(props) => (props.big ? "210px" : "70px")};
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: polygon(
            calc(100% - 40px) 0%,
            100% 40px,
            100% 100%,
            0 100%,
            0 0
        );
    `,

    SingleFormRect: styled.div`
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        clip-path: polygon(
            calc(100% - 39px) 0%,
            100% 39px,
            100% 100%,
            0 100%,
            0 0
        );
    `,
};

export default SingleFormBoxStyles;
