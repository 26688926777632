import styled from "styled-components";

const ContactModalStyles = {
    Container: styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: ${(props) => (props.active ? "flex" : "none")};
        justify-content: center;
        align-items: center;
        z-index: 10;
    `,

    MainWindow: styled.div`
        width: 700px;
        height: 400px;
        border-radius: 10px;
        border: 1px solid gray;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: white;
    `,

    FirstMessage: styled.div`
        font-family: "Libre Franklin";
        font-size: 2rem;
        color: green;
    `,

    ErrorMessage: styled.div`
        font-family: "Libre Franklin";
        font-size: 2rem;
        color: red;
    `,

    SecondMessage: styled.div`
        font-family: Barcade;
        font-size: 1.5rem;
    `,
};

export default ContactModalStyles;
