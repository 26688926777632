import React from "react";
import SignUpButton from "../../../Button/SignUpButton";
import ContactModalStyles from "./ContactModal.styles";

const { Container, MainWindow, FirstMessage, SecondMessage, ErrorMessage } =
    ContactModalStyles;

const ContactModal = ({ active, success, setter }) => {
    return (
        <Container active={active}>
            <MainWindow>
                {success ? (
                    <>
                        <FirstMessage>
                            Message submitted successfully.
                        </FirstMessage>
                        <SecondMessage>Thank you!</SecondMessage>
                    </>
                ) : (
                    <>
                        <ErrorMessage>
                            There was an error, try again later.
                        </ErrorMessage>
                        <SecondMessage>We're sorry</SecondMessage>
                    </>
                )}
                <SignUpButton modal onClick={() => setter(false)} />
            </MainWindow>
        </Container>
    );
};

export default ContactModal;
