import React, { useState } from "react";
import ContactFormStyles from "./ContactForm.styles";
import SingleFormBox from "./SingleFormBox/SingleFormBox";
import SignUpButton from "../../Button/SignUpButton";
import { StaticImage } from "gatsby-plugin-image";
import { useFormik } from "formik";
import { Buffer } from "buffer";
import { serialize } from "./Util";
import emailjs from "@emailjs/browser";
import ContactModal from "./ContactModal/ContactModal";
import ReCaptcha from "./ReCaptcha/ReCaptcha";

const {
    Container,
    FormContainer,
    LeadText,
    LeadTextBold,
    FirstRowForms,
    SingleRowContainer,
    Label,
    Error,
    Form,
    InputField,
    TextAreaField,
    ButtonContainer,
    LeftSquares,
} = ContactFormStyles;

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = "Required";
    }

    if (!values.email) {
        errors.email = "Required";
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    }

    if (!values.message) {
        errors.message = "Required";
    } else if (values.message.length < 10) {
        errors.message = "Must be at least 10 characters";
    }

    return errors;
};

const authentication = Buffer.from(
    "e4dc66cafe9f42259dadcfe6ab4ce06e140d13ad:130a0cd42183c8a0fb09fc96a358dbdaeb34b626"
).toString("base64");

const ContactForm = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(true);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phoneNumber: "",
            company: "",
            message: "",
            from_page: "OakFusion - Landing Page",
        },
        validate,
        onSubmit: (values) => {
            if (captchaVerified) {
                formik.resetForm();
                emailjs
                    .send(
                        "service_oakfusion",
                        "template_default",
                        values,
                        "q-NvkNe0iUoTYQftj"
                    )
                    .then(
                        (result) => {
                            setModalOpen(true);
                        },
                        (error) => {
                            setModalSuccess(false);
                            setModalOpen(true);
                        }
                    );
            } else {
                alert("Please fill the CAPTCHA field");
            }
            // const requestOptions = {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({
            //         subject: "OakForge - landing",
            //         email: values.email,
            //         name: values.name,
            //         phone: values.phoneNumber,
            //         company: values.company,
            //         message: values.message,
            //     }),
            // };
            // fetch("http://localhost:3000/contact", requestOptions)
            //     .then(
            //         (response) => response.json(),
            //         (error) => console.log(error)
            //     )
            //     .then((data) => console.log(data));
            // const bodyWithValues = {
            //     to: {
            //         "jsmolka@oakfusion.pl": "",
            //     },
            //     subject: `OakForge - landing page`,
            //     html: `<h1>OakForge - message</h1>
            //         <h2>From: ${values.name}</h2>
            //         <h3>Email: ${values.email}, phone: ${values.phoneNumber}</h3>
            //         <h3>Company: ${values.company}</h3>
            //         <p>${values.message}</p>`,
            //     smtp_account: "1.oakfusion.smtp",
            //     from: "jsmolka@oakfusion.pl",
            //     text: values.message,
            // };
            // const requestOptions = {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/x-www-form-urlencoded",
            //         Authorization: `Basic ${authentication}`,
            //     },
            //     body: serialize(bodyWithValues),
            // };
            // fetch(
            //     "https://api.emaillabs.net.pl/api/new_sendmail",
            //     requestOptions
            // )
            //     .then(
            //         (response) => response.json(),
            //         (error) => console.log(error)
            //     )
            //     .then((data) => console.log(data));
        },
    });

    return (
        <Container>
            <FormContainer>
                <ContactModal
                    active={modalOpen}
                    success={modalSuccess}
                    setter={setModalOpen}
                />

                <LeadText>
                    Fill out the form or write to us at:{" "}
                    <LeadTextBold>office@oakfusion.pl</LeadTextBold>
                </LeadText>

                <Form onSubmit={formik.handleSubmit}>
                    <FirstRowForms>
                        <SingleRowContainer>
                            <Label>
                                {formik.values.name ? "Your name" : ""}
                            </Label>
                            <SingleFormBox>
                                <InputField
                                    id="name"
                                    name="name"
                                    type="text"
                                    maxLength={45}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={"Your name"}
                                />
                            </SingleFormBox>
                            <Error>
                                {formik.touched.name && formik.errors.name
                                    ? formik.errors.name
                                    : ""}
                            </Error>
                        </SingleRowContainer>
                        <SingleRowContainer>
                            <Label>
                                {formik.values.phoneNumber
                                    ? "Phone number - optional"
                                    : ""}
                            </Label>
                            <SingleFormBox>
                                <InputField
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="number"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={"Phone number - optional"}
                                />
                            </SingleFormBox>
                            <Error></Error>
                        </SingleRowContainer>
                    </FirstRowForms>
                    <FirstRowForms>
                        <SingleRowContainer>
                            <Label>
                                {formik.values.email ? "Email Address" : ""}
                            </Label>
                            <SingleFormBox>
                                <InputField
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={"Email Address"}
                                />
                            </SingleFormBox>
                            <Error>
                                {formik.touched.email && formik.errors.email
                                    ? formik.errors.email
                                    : ""}
                            </Error>
                        </SingleRowContainer>
                        <SingleRowContainer>
                            <Label>
                                {formik.values.company
                                    ? "Company - optional"
                                    : ""}
                            </Label>
                            <SingleFormBox>
                                <InputField
                                    id="company"
                                    name="company"
                                    type="text"
                                    maxLength={1500}
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={"Company - optional"}
                                />
                            </SingleFormBox>
                            <Error></Error>
                        </SingleRowContainer>
                    </FirstRowForms>

                    <Label>
                        {formik.values.message ? "Your message..." : ""}
                    </Label>
                    <SingleFormBox big>
                        <TextAreaField
                            id="message"
                            name="message"
                            type="text"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder={"Your message..."}
                        />
                    </SingleFormBox>
                    <Error>
                        {formik.touched.message && formik.errors.message
                            ? formik.errors.message
                            : ""}
                    </Error>
                    <ReCaptcha onChange={setCaptchaVerified} />
                    <ButtonContainer type="sumbit">
                        <SignUpButton submit />
                    </ButtonContainer>
                </Form>
            </FormContainer>

            <LeftSquares>
                <StaticImage
                    src="../../../images/Contact/right-squares.png"
                    alt="Right squares"
                    placeholder="blurred"
                    quality={100}
                />
            </LeftSquares>
        </Container>
    );
};

export default ContactForm;
