import styled from "styled-components";
import bgImage from "../../../images/Contact/bg.png";
import bgImageMobile from "../../../images/Contact/bg-mobile.png";

const ContactHomeStyles = {
    Container: styled.div`
        position: relative;
        width: 100%;
        /* height: max-content; */
        height: 750px;
        /* background: linear-gradient(
            90deg,
            rgb(0, 0, 0, 1) 0%,
            rgba(206, 222, 30, 1) 250%
        ); */
        background-image: url(${bgImage});
        background-size: cover;
        @media (max-width: 900px) {
            background-image: url(${bgImageMobile});
        }
    `,

    BackgroundContainer: styled.div`
        position: relative;
    `,

    ShinySquareContainer: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    ShinySquareMiddle: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        bottom: -50px;
        max-width: 43vw;
        @media (max-width: 900px) {
            max-width: 400px;
        }
    `,

    ManInsideContainer: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    ManInsideSquare: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        left: 100px;
        max-width: 53vw;
        @media (max-width: 900px) {
            max-width: 400px;
            left: 50px;
        }
    `,

    TitleContainer: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    `,

    TitlePositionContainer: styled.div`
        position: absolute;
        width: 300px;
        left: 5%;
        top: 25vw;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-start;
        /* border: 2px solid white; */
        @media (max-width: 900px) {
            top: 200px;
        }
    `,

    TitleBoxContainer: styled.div`
        width: 100%;
        /* height: 100px; */
        border-bottom: 2px solid rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    `,

    TitleBox: styled.div`
        width: 85%;
        height: 30px;
        text-align: center;
        border-left: 2px solid rgba(255, 255, 255, 0.6);
        border-right: 2px solid rgba(255, 255, 255, 0.6);
    `,

    TitleText: styled.div`
        font-family: "Barcade";
        font-size: 1.8rem;
        color: #eefc57;
        text-shadow: 0px 0px 30px #eefc57;
        position: relative;
        bottom: 20px;
        left: 3px;
    `,

    SubTitleTextBottom: styled.div`
        font-size: 1.8rem;
        color: white;
        padding-left: 43px;
        margin-top: 20px;
    `,

    LeftSquares: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        display: ${(props) => (props.mobile ? "none" : "flex")};
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 900px) {
            display: ${(props) => (props.mobile ? "flex" : "none")};
            align-items: flex-end;
        }
    `,

    RightSquares: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        display: ${(props) => (props.mobile ? "none" : "flex")};
        justify-content: flex-end;
        align-items: flex-end;
        @media (max-width: 900px) {
            display: ${(props) => (props.mobile ? "flex" : "none")};
            align-items: center;
            bottom: 11%;
        }
    `,

    LightCircle: styled.div`
        position: absolute;
        /* width: 20%; */
        width: 350px;
        height: 40%;
        filter: blur(150px);
        /* background-color: rgba(238, 252, 0, 0.6); */
        background-color: rgba(238, 252, 87, 0.5);
        border-radius: 50%;
        /* top: 40%; */
        top: 18vw;
        left: 5%;
        @media (max-width: 900px) {
            display: none;
        }
    `,
};

export default ContactHomeStyles;
