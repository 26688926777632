import React from "react";
import ContactForm from "../components/Contact/ContactForm/ContactForm";
import ContactHome from "../components/Contact/Home/ContactHome";
import Layout from "../components/Layout/Layout";
import { Helmet } from "react-helmet";

const Signup = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>OakForge - Contact</title>
                <meta
                    name="description"
                    content="Oakforge - your best starting place in IT world"
                />
            </Helmet>
            <ContactHome />
            <ContactForm />
        </Layout>
    );
};

export default Signup;
