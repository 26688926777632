import styled from "styled-components";

const ContactFormStyles = {
    Container: styled.div`
        width: 100%;
        position: relative;
        height: 1000px;
        /* height: max-content; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 900px) {
            height: 1300px;
        }
    `,

    FormContainer: styled.div`
        width: 60%;
        margin-left: 10%;
        /* height: 600px; */
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 130px;
        margin-bottom: 130px;
    `,

    LeadText: styled.div`
        font-size: 1.2rem;
    `,

    LeadTextBold: styled.span`
        font-weight: bold;
    `,

    Form: styled.form`
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin: 50px 0px;
    `,

    FirstRowForms: styled.div`
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        /* border: 2px solid black; */
        gap: 5%;
        @media (max-width: 900px) {
            flex-flow: column;
            justify-content: space-between;
            gap: 20px;
        }
    `,

    SingleRowContainer: styled.div`
        display: flex;
        flex-flow: column;
        width: 100%;
    `,

    Label: styled.div`
        width: 85%;
        /* border: 2px solid black; */
        height: 20px;
        margin-bottom: 10px;
        margin-left: 7px;
    `,

    Error: styled.div`
        width: 85%;
        /* border: 2px solid black; */
        height: 20px;
        margin-top: 10px;
        margin-left: 7px;
        color: #d20808;
    `,

    InputField: styled.input`
        width: 90%;
        margin-left: 10px;
        height: 80%;
        font-size: 1.2rem;
        border: none;
        outline: none;
        background: none;
        background-color: none;
    `,

    TextAreaField: styled.textarea`
        width: 90%;
        margin-left: 10px;
        height: 80%;
        font-size: 1.2rem;
        border: none;
        outline: none;
    `,

    ButtonContainer: styled.button`
        width: max-content;
        border: 0;
        background-color: white;
        margin: 0;
        margin-top: 40px;
        padding: 0;
    `,

    LeftSquares: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        z-index: -1;
        @media (max-width: 900px) {
            display: none;
        }
    `,
};

export default ContactFormStyles;
