import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptcha = ({ onChange }) => {
    return (
        <ReCAPTCHA
            sitekey="6Lew6PcfAAAAADlY94iO2ZVM8rbPTZMq52OXOhu6"
            onChange={() => onChange(true)}
            onExpired={() => onChange(false)}
            style={{ marginTop: "20px" }}
        />
    );
};

export default ReCaptcha;
